@use '../_variables' as var
    
header
    display: flex
    align-items: center
    justify-content: flex-end

    .logo
        margin: 0 auto
        margin-left: 0

        @media (max-width: 850px) 
            position: absolute
            left: 16px
            top: 32px
    .linkedin
        font-weight: 600
        font-size: 16px
        color: var.$black
        text-decoration: underline
        margin-right: 36px

        @media (max-width: 850px) 
            font-size: 14px
            width: 89px
            text-align: right
            margin: 51px 16px 0 0

    .btn
        @media (max-width: 850px) 
            display: none
.btn 
    border-radius: 50px
    background-color: var.$blue
    padding: 8px 24px
    font-weight: 600
    font-size: 16px
    color: var.$grey