@use '../_variables' as var
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,600;1,700;1,800;1,900&display=swap')

.box1
    display: flex
    margin-top: 88.73px

    @media (max-width: 850px)
        flex-direction: column
        margin: 42px 16px

    .text-container
        max-width: 456px

        .small-text
            font-weight: 500
            font-size: 18px

        .home-text
            font-weight: 600
            font-size: 64px
            line-height: 90%
            margin-bottom: 24px

            span
                color: var.$blue
                display: block

        .description
            width: 380px
            margin-bottom: 24px

        .orcamento
            padding: 16px 24px
            margin-right: 16px

            @media (max-width: 578px)
                display: flex
                flex-direction: row
                justify-content: center
                align-items: center
                width: 100%

        .whatsapp
            border-radius: 50px
            background: none
            border: 2px solid var.$green
            color: var.$green
            padding: 16px 24px

            &:hover
                cursor: pointer

            @media (max-width: 578px)
                display: flex
                flex-direction: row
                justify-content: center
                align-items: center
                margin-top: 10px
                width: 100%

            .icon-whatsapp
                float: left
                margin-right: 10px

            .txt-btn
                font-weight: 600
                font-size: 16px

    .imagem
        width: 100%
        margin: 32px 0

.box2

    background: radial-gradient(50% 50% at 50% 55%, #a3d0ee -10%, rgba(255, 255, 255, 0) 90%)
    height: 702px
    padding-top: 80px

    @media (max-width: 850px)
        padding: 0
        background: radial-gradient(150% 50% at 50% 50%, #a3d0ee -10%, rgba(255, 255, 255, 0) 100%)
        height: 1780px

    .title-box2
        font-weight: 600
        font-size: 32px
        @media (max-width: 850px)
            font-size: 24px
            text-align: center

    .subtitle-box2
        font-weight: 600
        font-size: 16px
        color: var.$blue
        margin-bottom: 24px
        @media (max-width: 850px)
            text-align: center

    .cards-container
        display: flex
        flex-direction: row
        align-items: flex-start
        gap: 20px

        @media (max-width: 850px)
            flex-direction: column
            align-items: center

        .cards
            width: 264px
            background: #fff
            padding: 40px 32px
            border-radius: 16px
            box-shadow: 0px 0px 30px rgba(29, 155, 240, 0.1)
            .icon
                margin-bottom: 24px

            .title
                margin-bottom: 24px
