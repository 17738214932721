@use 'variables' as var
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,600;1,700;1,800;1,900&display=swap')

*
    font-family: 'Poppins', sans-serif
    margin: 0
    padding: 0
    box-sizing: border-box
    list-style: none
    text-decoration: none

.App
    max-width: 1128px
    margin: 67px auto 0

    @media (max-width: 850px)
        margin: 0
