.footer
    padding: 40px

    @media (max-width: 850px) 
        padding: 42px 78px 42px    
        margin: 0

    .copy
        text-align: center

        @media (max-width: 850px)
            width: 203px
            font-size: 14px
            margin: auto

    span
        @media (max-width: 850px)
            display: block
